const invert = require('lodash/invert');

const chapterNumbers = {
  'The Robust UI Mindset': 1,
  'Testing Practices': 2,
  'Test Structure': 3,
  'Compound Components': 4,
  Hooks: 5,
  Mocks: 6,
  'Base/Variants Pattern': 7,
  'Visual Testing': 8,
};

module.exports = {
  chapterNumbers: chapterNumbers,
  chapterTitles: invert(chapterNumbers),
};
