import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { getActiveClasses } from 'get-active-classes';
import { rhythm } from '../utils/typography';
import MenuSvg from '../svgs/menu.svg';
import { Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/react';
import { chapterNumbers } from '../utils/chapter';

export function Sidebar({ chapter, className }) {
  const [menuOpen, setmenuOpen] = useState(false);
  const results = useStaticQuery(graphql`
    {
      allMdx {
        chapters: group(field: frontmatter___chapter) {
          chapter: fieldValue
          sections: nodes {
            frontmatter {
              title
              chapter
              section
              incomplete
              hidden
            }
          }
        }
      }
    }
  `);

  const chapters = React.useMemo(() => {
    return results.allMdx.chapters
      .filter(c => chapterNumbers[c.chapter] != null)
      .sort((a, b) =>
        chapterNumbers[a.chapter] > chapterNumbers[b.chapter] ? 1 : -1
      )
      .filter(({ sections }) => {
        return sections.some(({ frontmatter }) => !frontmatter.hidden);
      });
  }, [results]);

  const { sections = [] } =
    chapters.find(group => group.chapter == chapter) || {};

  const sortSections = unsortedSections => {
    return [...unsortedSections].sort((a, b) =>
      parseInt(a.frontmatter.section) > parseInt(b.frontmatter.section) ? 1 : -1
    );
  };

  const linkClasses = 'underline inline-block text-black hover:text-blue-600';

  return (
    <div className={className}>
      <aside className="block sm:hidden w-full mt-8">
        <button
          className="mx-auto flex items-center rounded-full px-6 py-2 shadow-none border-none appearance-none font-bold cursor-pointer hover:bg-gray-700 bg-gray-800 text-white text-lg"
          onClick={() => {
            setmenuOpen(true);
          }}
        >
          <MenuSvg className="m-0 mr-2" aria-hidden />
          Robust UI
          <span className="sr-only">Menu Chapters</span>
        </button>
      </aside>
      <aside
        className="max-w-sm w-full top-0 sticky flex-1 h-screen flex-shrink-0 hidden sm:block mr-10"
        style={{
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)} ${rhythm(1.5)}`,
        }}
      >
        <button
          className="flex items-center rounded-full px-6 py-2 shadow-none border-none appearance-none font-bold cursor-pointer hover:bg-gray-700 bg-gray-800 text-white text-lg"
          onClick={() => {
            setmenuOpen(true);
          }}
        >
          <MenuSvg className="m-0 mr-2" aria-hidden />
          Robust UI
          <span className="sr-only">Menu Chapters</span>
        </button>
        <h2 className="mt-10">
          <div></div>
          {chapter}
        </h2>
        {sortSections(sections).map(
          ({ frontmatter: { section, title, incomplete, hidden } }) => {
            if (hidden) return null;

            if (incomplete) {
              return (
                <div className="mb-1" key={title}>
                  {title} (coming soon)
                </div>
              );
            }

            return (
              <div key={`${title}`}>
                <Link
                  className={getActiveClasses(linkClasses, 'mb-1')}
                  style={{ boxShadow: `none` }}
                  to={`/chapter-${chapterNumbers[chapter]}#section-${section}`}
                >
                  {title}
                </Link>
              </div>
            );
          }
        )}
      </aside>
      <Drawer
        isOpen={menuOpen}
        onClose={() => setmenuOpen(false)}
        placement="left"
        size="sm"
        closeOnEsc
        closeOnOverlayClick
      >
        <DrawerOverlay>
          <DrawerContent>
            <nav
              className={getActiveClasses(
                'bg-gray-100 shadow-lg py-6 px-10 overflow-auto'
              )}
            >
              <ol start="0" className="list-decimal">
                <li className="m-0">
                  <section>
                    <h2 className="text-lg mb-4 mt-8">
                      <Link
                        to={`/`}
                        className={linkClasses}
                        style={{ boxShadow: `none` }}
                      >
                        Welcome
                      </Link>
                    </h2>
                  </section>
                </li>
                {chapters.map(chapterGroup => (
                  <li key={chapterGroup.chapter} className="m-0">
                    <section>
                      <h2 className="text-lg mb-4 mt-8">
                        {chapterGroup.chapter}
                      </h2>
                      <ul className="list-none m-0">
                        {sortSections(chapterGroup.sections).map(section => {
                          if (section.frontmatter.hidden) return null;

                          if (section.frontmatter.incomplete) {
                            return (
                              <div
                                key={section.frontmatter.title}
                                className="mb-1"
                              >
                                {section.frontmatter.title} (coming soon)
                              </div>
                            );
                          }

                          return (
                            <li
                              key={section.frontmatter.title}
                              className="mb-1"
                            >
                              <Link
                                to={`/chapter-${
                                  chapterNumbers[chapterGroup.chapter]
                                }#section-${section.frontmatter.section}`}
                                className={linkClasses}
                                style={{ boxShadow: `none` }}
                              >
                                {section.frontmatter.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </section>
                  </li>
                ))}
              </ol>
            </nav>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </div>
  );
}
